.site-layout-background {
    background: #111;
}

.logo>img {
    height: 4rem;
    padding: 10px;
    margin: auto;
    margin: auto;
}

.dashboard-card {
    margin-bottom: 16px;
    border-radius: 8px;
    text-align: center;
}

.site-layout-sub-header-background {
    display: flex;
    justify-content: flex-end;
}

.ant-menu-title-content>.nav-text {
    margin-left: 0.4rem;
}

.ant-statistic-title {
    color: #ebf5ff;
}

.highcharts-credits {
    display: none;
}