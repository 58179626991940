.square {
  margin: 0 auto;
  width: 50%;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.stove-off {
  background: url('/stoves/off.png');
  background-size: cover;

}

.stove-on {
  background: url('/stoves/on.png');
  background-size: cover;
}

.fireAlarm-off {
  background: url('/fires/off.png');
  background-size: cover;

}

@keyframes flickerAnimation {
  0% {
    border: 3px solid #e23b0e;
  }

  50% {
    border: 3px solid transparent;
  }

  100% {
    border: 3px solid #e23b0e;
  }
}

.fireAlarm-on {
  background: url('/fires/on.png');
  background-size: cover;

  animation: flickerAnimation 2s infinite;

}

.connection {
  background: url('/connection.jpg');
  background-size: cover;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;

  &:hover {
    filter: hue-rotate(200deg);
  }
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 25px;
  height: 25px;
  background: #4CAF50;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #4CAF50;
  cursor: pointer;
}

@mixin aspect-ratio($width, $height) {
  position: relative;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  >.content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

*:focus {
  outline: none;
}

.room-icon {

  input {
    background: transparent;
    border: none;
    color: white;
  }

  margin-bottom: 20px;
  @include aspect-ratio(5, 3);

  background-size: cover;

  .content {
    padding: 10px;
  }

  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;

  font-size: 1.6rem;

  a {
    color: white;

    &:hover {
      color: white;
      text-decoration: none;
    }
  }

}

@media (min-width: 768px) {
  .navbar .navbar-nav {
    display: inline-block;
    float: none;
    vertical-align: top;
  }

  .navbar .navbar-collapse {
    text-align: center;
  }
}

.text-center {
  text-align: center;
}

.sq {
  @include aspect-ratio(1, 1);

  &:before {
    background-color: #333;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.sq-fire {
  &:before {
    animation: flickerAnimation 2s infinite;
  }

}

.fire,
.stove {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  margin-bottom: 14px;
}

.bw {
  filter: grayscale(100%);
}

@-webkit-keyframes autofill {

  0%,
  100% {
    color: #666;
    background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-delay: 1s;
  /* Safari support - any positive time runs instantly */
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

.hidden-mobile {
  @media(max-width: 576px) {
    display: none !important;
  }
}

.hidden-not-mobile {
  @media(min-width: 577px) {
    display: none !important;
  }
}



.min-h-screen-no-mobile {
  min-height: 100vh;

  @media(max-width: 576px) {
    min-height: inherit;
  }
}

.p-4-no-mobile {
  padding: 5rem;

  @media(max-width: 576px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.max-width-mobile {

  @media(max-width: 576px) {
    width: 100%;
  }
}

.span-color-white {
  white-space: nowrap;
  color: hsla(0,0%,100%,.85);
}